import { Container } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { GetServerSideProps } from 'next'
import { getServerSession } from 'next-auth'
import { FormProvider, useForm } from 'react-hook-form'
import { setCookie } from 'nookies'

import { routes } from 'routes'

import Logo from 'assets/Logo'
import LoginForm, { formOptions, LoginFormData } from 'components/auth/LoginForm'
import { getLayout } from 'components/layouts/AuthLayout'

import { authOptions } from './api/auth/[...nextauth]'
import { cookieDomain, externalRoutes } from 'external-routes'

import { generateSecret } from './api/auth/generate-secret'

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { callbackUrl, retryLogin, v1redirectURL } = context.query
  if (callbackUrl && typeof callbackUrl === 'string') {
    const match = callbackUrl.match(/(.*)\?idp=([^?]+)$/)
    if (match && match.length > 2) {
      const hashedAccount = decodeURIComponent(match[2])
      const decodedName = Buffer.from(hashedAccount, 'base64').toString('utf-8')
      if (decodedName.length > 0) {
        setCookie(context, 'ssoRedirectUrl', match[1], {
          maxAge: 60 * 60 * 24 * 2 - 5,
          path: '/',
          domain: cookieDomain,
        })
        return {
          redirect: {
            destination: externalRoutes.SSOLoginUrl + `/${decodedName}/login`,
            permanent: false,
          },
        }
      }
    }
  }

  const session = await getServerSession(context.req, context.res, authOptions)
  if (!session || !session.user.id) {
    return { props: {} }
  }

  // If the user is already logged in v2, log them in v1
  if (session && typeof retryLogin === 'string' && retryLogin === 'true') {
    try {
      const result = await generateSecret(session)
      if (result.userEmail && result.hashedSecret) {
        const v1Url =
          typeof v1redirectURL === 'string' && v1redirectURL.trim().length > 0
            ? v1redirectURL
            : externalRoutes.v1.workshopsDashboard
        return {
          redirect: {
            destination:
              externalRoutes.v1.magicLink +
              `?e=${encodeURIComponent(result.userEmail)}&s=${encodeURIComponent(
                result.hashedSecret,
              )}&u=${encodeURIComponent(v1Url)}`,
            permanent: false,
          },
        }
      }
    } catch (error) {
      console.error('Error during fetch request:', error)
      if (!(error instanceof Error)) {
        throw error
      }
    }

    return { props: {} }
  }

  return { redirect: { destination: routes.root, permanent: false } }
}

// Update the function definition to use the defined props type
export default function LoginPage() {
  const methods = useForm<LoginFormData>(formOptions)

  return (
    <Container maxWidth="sm">
      <Box sx={{ p: 2, textAlign: 'center', bgcolor: 'white' }}>
        <Grid container justifyContent="center" sx={{ my: 3 }}>
          <Grid item xs={12} mb={3}>
            <Logo size={55} />
          </Grid>
          <Grid item sm={10}>
            <FormProvider {...methods}>
              <LoginForm />
            </FormProvider>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

LoginPage.getLayout = getLayout
