import CheckIcon from '@mui/icons-material/Check'
import { Slide, SlideProps } from '@mui/material'
import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import { ToastEntry } from './ToastProvider'

function SlideLeft(props: SlideProps) {
  return <Slide {...props} direction="left" />
}

type Props = ToastEntry & { onClose?: () => void }

export default function GenericToast({
  id,
  color,
  message,
  open,
  onClose,
  duration = 3000,
  action,
  disableClickAway = false,
}: Props) {
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (disableClickAway && reason === 'clickaway') {
      return
    }
    onClose?.()
  }

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      autoHideDuration={duration}
      sx={{ position: 'relative' }}
      TransitionComponent={SlideLeft}
      TransitionProps={{ enter: true, exit: true }}
      transitionDuration={{ enter: 200, exit: 200 }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
      <Alert
        id={id}
        variant="filled"
        severity={color}
        onClose={onClose}
        iconMapping={{ success: <CheckIcon fontSize="inherit" /> }}
        sx={{ color: 'white', width: '100%', minWidth: '10rem' }}
        action={action}>
        {message}
      </Alert>
    </Snackbar>
  )
}
