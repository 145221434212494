import getConfig from 'next/config'
import { ReactNode, useCallback, useEffect, useState } from 'react'

import InfoDialog from 'components/dialogs/InfoDialog'
import useConfirm from 'hooks/useConfirm'

const {
  publicRuntimeConfig: { env },
} = getConfig()

const useBuildId = () => {
  const shouldReload = useCallback(async (): Promise<boolean> => {
    if (env === 'development') return false

    const nextData = document.querySelector('#__NEXT_DATA__')
    if (!nextData?.textContent) return false
    const buildId = JSON.parse(nextData.textContent).buildId
    try {
      const url = `/_next/static/${buildId}/_buildManifest.js`
      const response = await fetch(url, {
        method: 'HEAD',
        headers: {
          Pragma: 'no-cache',
          'Cache-Control': 'no-cache',
          'If-Modified-Since': 'Thu, 01 Jun 1970 00:00:00 GMT',
        },
        cache: 'no-store',
      })
      // buildId stores the current version of the app and when we do the release, this id changes
      // If we have a tab opened before the release, it keeps checking with the old (stored) buildId.
      // So we have status !== 200 (returns 404) because buildId has changed => shouldReload returns true and dialog is shown.
      return response.status !== 200
    } catch (error) {
      console.error(error)
    }
    return false
  }, [])

  return {
    shouldReload,
  }
}

export function AppVersionCheckProvider({ children }: { children?: ReactNode }) {
  const { shouldReload } = useBuildId()
  const [lastFocusCheck, setLastFocusCheck] = useState<Date>(new Date())
  const [reloading, setReloading] = useState<boolean>(false)

  const appVersionCheck = useConfirm({
    disableBackdropClose: true,
    onConfirm() {
      window.location.reload()
      setReloading(true)
    },
  })

  const checkForUpdates = useCallback(
    async function () {
      shouldReload().then((result) => {
        if (result) appVersionCheck.openHandler()
      })
    },
    [appVersionCheck, shouldReload],
  )

  // check for updates on every 60 seconds
  useEffect(() => {
    if (env === 'development') return
    const interval = setInterval(() => {
      checkForUpdates()
    }, 60000)
    return () => clearInterval(interval) // Cleanup on unmount
  }, [checkForUpdates])

  // check for updates when the user focuses the tab and has not
  // been checked in the last 60 seconds
  useEffect(() => {
    if (env === 'development') return
    const focusHandler = () => {
      const now = new Date()
      if (now.getTime() - lastFocusCheck.getTime() >= 60000) {
        checkForUpdates()
        setLastFocusCheck(now)
      }
    }
    window.addEventListener('focus', focusHandler)
    return () => window.removeEventListener('focus', focusHandler) // Cleanup on unmount
  }, [checkForUpdates, lastFocusCheck]) // Dependency array

  if (reloading) return <></>

  return (
    <>
      {children}
      {appVersionCheck.open && (
        <InfoDialog
          title="Reload required"
          infoMessage="A new version of this app is available. The page will now reload."
          {...appVersionCheck}
          disableClose
        />
      )}
    </>
  )
}
