import InfoDialog from 'components/dialogs/InfoDialog'
import { cookieDomain } from 'external-routes'
import useConfirm from 'hooks/useConfirm'
import { useSession } from 'next-auth/react'
import { parseCookies, setCookie } from 'nookies'
import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useUser } from './UserProvider'
import getConfig from 'next/config'
import Script from 'next/script'

export interface Weglot {
  initialize: ({
    api_key,
    cache,
    hide_switcher,
  }: {
    api_key: string
    cache?: boolean
    hide_switcher?: boolean
  }) => void
  on: (event: string, callback: (...args: string[]) => void) => void
  translate: (_: unknown, callback: (data: string) => void) => void
  getCurrentLang: () => string
  getLanguageName: (code: string) => string
  switchTo: (code: string) => void
  initialized: boolean
  options: Options
  dynamic: string
}

interface Options {
  is_connect: boolean
  private_mode: boolean
  button_style: ButtonStyle
  switchers: Switcher[]
  auto_switch: boolean
  auto_switch_fallback: null
  excluded_blocks: ExcludedBlock[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  excluded_blocks_remove: any[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  whitelist: any[]
  translate_event: TranslateEvent[]
  customer_tag: boolean
  order_tag: boolean
  dynamics: Dynamic[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  excluded_paths: any[]
  wait_transition: boolean
  hide_switcher: boolean
  translate_search: boolean
  media_enabled: boolean
  search_forms: string
  cache: boolean
  live: boolean
  loading_bar: boolean
  search_parameter: string
  translation_engine: number
  override_hreflang: boolean
  api_key: string
  uid: string
  project_slug: string
  deleted_at: null
  language_from: string
  language_from_custom_flag: null
  language_from_custom_name: null
  external_enabled: boolean
  page_views_enabled: boolean
  versions: Versions
  pending_translation_enabled: boolean
  curl_ssl_check_enabled: boolean
  languages: Language[]
  category: number
  organization_slug: string
  technology_id: number
  technology_name: string
  translate_images: boolean
  subdomain: boolean
  dynamic: string
  is_tld: boolean
}

interface ButtonStyle {
  with_name: boolean
  full_name: boolean
  is_dropdown: boolean
  with_flags: boolean
  flag_type: string
  custom_css: string
}

interface Switcher {
  style: ButtonStyle
  location: Record<string, unknown>
  default: boolean
  ready: boolean
}

interface ExcludedBlock {
  value: string
  description: string
}

interface TranslateEvent {
  selector: string
  eventName: null
}

interface Dynamic {
  value: string
}

interface Versions {
  translation: number
  slugTranslation: number
}

interface Language {
  language_to: string
  custom_code: null
  custom_name: null
  custom_local_name: null
  provider: null
  enabled: boolean
  automatic_translation_enabled: boolean
  deleted_at: null
  connect_host_destination: null
  custom_flag: null
}

declare global {
  interface Window {
    Weglot: Weglot
  }
}

export type LanguagePair = { code: string; name: string }

type WeglotContextType = {
  changeLanguage: (code: string) => void
  selectedLanguage?: string
  availableLanguages?: LanguagePair[]
  initialized?: boolean
  errorLoading?: boolean | null
}

const {
  publicRuntimeConfig: { weglotToken, env },
} = getConfig()

const WeglotContext = createContext<WeglotContextType | undefined>(undefined)

export const useWeglot = () => {
  const context = useContext(WeglotContext)
  if (!context) {
    console.warn('Misuse of useWeglot. It must be used within a WeglotProvider. ')
  }
  return context
}

export const WeglotProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedLanguage, setSelectedLanguage] = useState<string | undefined>('en')
  const [errorLoading, setErrorLoading] = useState(false)
  const [initialized, setInitialized] = useState(false)
  const cookies = parseCookies()
  const { user, userLanguages } = useUser()
  const session = useSession()

  const userLanguageCode = useMemo(() => {
    return userLanguages?.find((x) => x.languageCode == user?.languageCode)?.isoLangCode || 'en'
  }, [user, userLanguages])

  const changeLanguage = useCallback((code: string) => {
    window?.Weglot?.switchTo(code)
  }, [])

  useEffect(() => {
    if (!window?.Weglot || !userLanguageCode) return
    const loggedAsUser = session?.data?.prevToken
    // has session, get the language from the user if you're not logged as this user from admin
    // console.log({ userLanguageCode })
    changeLanguage(loggedAsUser ? 'en' : userLanguageCode)
  }, [session, userLanguageCode, changeLanguage])

  const adblockPopup = useConfirm({
    disableBackdropClose: true,
  })

  useEffect(() => {
    if (errorLoading && env !== 'testing') {
      if (!cookies.weglotLoadErrorShown) {
        setCookie(null, 'weglotLoadErrorShown', 'true', {
          maxAge: 60 * 10, // 10 minutes
          path: '/',
          domain: cookieDomain,
        })

        adblockPopup.openHandler()
      }
    }
  }, [cookies.weglotLoadErrorShown, adblockPopup, errorLoading])

  useEffect(() => {
    if (!initialized || errorLoading || !window.Weglot) return

    try {
      setSelectedLanguage(window.Weglot.getCurrentLang())

      window.Weglot.on('languageChanged', (newLang: string) => {
        setSelectedLanguage(() => newLang)
      })
    } catch (err) {
      console.error(err)
    }
  }, [errorLoading, initialized])

  return (
    <>
      {adblockPopup.open && (
        <InfoDialog
          title="Translations not available"
          infoMessage="Please disable your ad blocker to use our translation feature."
          {...adblockPopup}
          disableClose
        />
      )}
      <Script
        src="https://cdn.weglot.com/weglot.min.js"
        strategy="afterInteractive"
        onError={() => {
          setErrorLoading(true)
          setInitialized(false)
          console.warn('Error loading Weglot. No translations available')
        }}
        onLoad={() => {
          setErrorLoading(false)

          window.Weglot.initialize({
            api_key: weglotToken,
            hide_switcher: true,
          })

          window.Weglot.on('initialized', () => {
            setInitialized(true)
            localStorage.removeItem('WEGLOT_APIKEY') // NOTE: remove old key cache
          })
        }}
      />
      <WeglotContext.Provider
        value={{
          initialized,
          errorLoading,
          changeLanguage,
          selectedLanguage,
        }}>
        {children}
      </WeglotContext.Provider>
    </>
  )
}

export const useWeglotTranslate = (text?: string) => {
  const instance = useWeglot()
  const weglot = window?.Weglot
  const selectedLanguage = instance?.selectedLanguage
  const [translatedText, setTranslatedText] = useState(text)

  useEffect(() => {
    if (!weglot || !selectedLanguage || !text) return

    const tryTranslate = async () => {
      try {
        weglot?.translate(
          {
            words: [{ t: 3, w: text }],
            selectedLanguage,
          },
          (data: string | string[]) => {
            setTranslatedText(Array.isArray(data) ? data?.[0] : data)
          },
        )
      } catch {
        console.warn('useWeglotTranslate error - cannot translate text')
        return
      }
    }

    tryTranslate()
  }, [text, selectedLanguage, weglot])

  return translatedText
}
