import { inputClasses } from '@mui/material'
import { createTheme } from '@mui/material/styles'
// needed so we can extend the theme with styleOverrides for DataGrid
// more here: https://mui.com/x/react-data-grid/getting-started/#typescript
import type {} from '@mui/x-data-grid/themeAugmentation'

import { colors } from './colors'
import { CSSProperties } from '@mui/material/styles/createMixins'

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    'contained-secondary': true
    'contained-muted': true
    'workshop-submit': true
    'soft-blue': true
    lowercase: true
  }
}

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    'outlined-dark': true
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    'inner-page-title': true
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    successDark: true
    successLight: true
    errorDark: true
    errorLight: true
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    mm: true
  }
  interface TypographyVariants {
    'inner-page-title': CSSProperties
  }
  interface TypographyVariantsOptions {
    'inner-page-title': CSSProperties
  }
  interface Palette {
    white: string
    bg: {
      white: string
      light: string
      medium: string
      main: string
      strong: string
      dark: string
    }
    backgroundSecondary: string
    backgroundLight: string
    bgDarkText: {
      primary: string
      secondary: string
    }
    dividerDark: string
    successDark: {
      main: string
      dark: string
      contrastText: string
    }
    successLight: {
      main: string
      dark: string
      contrastText: string
    }
    errorDark: {
      main: string
      dark: string
      contrastText: string
    }
    errorLight: {
      main: string
      dark: string
      contrastText: string
    }
  }
  interface PaletteOptions {
    white: string
    bg: {
      white: string
      light: string
      medium: string
      main: string
      strong: string
      dark: string
    }
    backgroundSecondary: string
    backgroundLight: string
    bgDarkText: {
      primary: string
      secondary: string
    }
    dividerDark: string
    successDark: {
      main: string
      dark: string
      contrastText: string
    }
    successLight: {
      main: string
      dark: string
      contrastText: string
    }
    errorDark: {
      main: string
      dark: string
      contrastText: string
    }
    errorLight: {
      main: string
      dark: string
      contrastText: string
    }
  }

  interface Theme {
    formFieldLabelWidth: {
      dense: number
      default: number
    }
  }

  interface ThemeOptions {
    formFieldLabelWidth?: {
      dense: number
      default: number
    }
  }
}

export const themeMinHeight = {
  siteToolbar: { xs: '70px', sm: '85px' },
}

export const zIndex = {
  appHeader: 30,
  subHeader: 20,
}

export const toolbarTransition = 'top 225ms cubic-bezier(0, 0, 0.2, 1) 0ms'

const theme = createTheme({
  palette: {
    white: colors.white,
    primary: {
      main: colors.greyDark,
      light: colors.background,
      dark: colors.backgroundDark,
    },
    secondary: {
      main: colors.yellowDark,
      light: colors.yellowLight,
      dark: colors.yellow,
    },
    info: {
      main: colors.blue,
      dark: colors.blueDark,
    },
    success: {
      main: colors.green,
      light: colors.greenLight,
      dark: colors.greenDark,
    },
    error: {
      main: colors.red,
      light: colors.redLight,
      dark: colors.redDark,
      contrastText: colors.black,
    },
    warning: {
      main: colors.yellowDark,
      light: colors.yellowLight,
      dark: colors.yellow,
    },
    text: {
      primary: colors.text,
      secondary: colors.textLight,
    },
    bgDarkText: {
      primary: colors.bgDarkText,
      secondary: colors.bgDarkTextLight,
    },
    divider: colors.greyLight,
    dividerDark: colors.borderDark,
    bg: {
      white: colors.white,
      light: colors.backgroundLight,
      medium: colors.textLight,
      main: colors.background,
      strong: colors.greyDark,
      dark: colors.backgroundDark,
    },
    backgroundSecondary: colors.backgroundSecondary,
    backgroundLight: colors.backgroundLight,
    successDark: {
      main: colors.greenDark,
      dark: colors.green,
      contrastText: colors.black,
    },
    successLight: {
      main: colors.greenLight,
      dark: colors.green,
      contrastText: colors.black,
    },
    errorDark: {
      main: colors.redDark,
      dark: colors.red,
      contrastText: colors.black,
    },
    errorLight: {
      main: colors.redLight,
      dark: colors.red,
      contrastText: colors.black,
    },
  },
  zIndex: {
    appBar: 30,
  },
  formFieldLabelWidth: {
    dense: 120,
    default: 160,
  },
  breakpoints: {
    keys: ['xs', 'sm', 'mm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 600,
      mm: 750,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: 'azo-sans-web',
    fontSize: 12.25,
    h1: { fontSize: '3rem', fontWeight: '400' },
    h2: { fontSize: '2.25rem', fontWeight: '400' },
    h3: { fontSize: '1.5rem', fontWeight: '400' },
    h4: { fontSize: '1.35rem', fontWeight: '400' },
    h5: { fontSize: '1.2rem', fontWeight: '400' },
    h6: { fontSize: '1rem', fontWeight: '400' },
    subtitle1: {
      lineHeight: '1.55em',
    },
    body1: {
      lineHeight: '1.55em',
    },
    button: {
      fontWeight: '500',
      letterSpacing: '0.04em',
    },
    'inner-page-title': {
      fontWeight: '400',
      fontSize: '1.375rem',
      color: colors.text,
    },
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          // Icons in MenuItem
          '& .MuiListItemIcon-root': { minWidth: '32px' },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: colors.greyLight,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          // Icons in MenuItem
          '& .MuiListItemIcon-root': { minWidth: '32px' },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          // Support multiple components on a single line in the selected menu item
          '& > *': {
            display: 'inline-block',
          },
          '& .MuiListItemIcon-root svg': {
            verticalAlign: 'middle',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: colors.backgroundDark,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          // Remove underline from standard inputs globally
          // Same as `disableUnderline: true` prop
          ...(ownerState.variant === 'standard' && {
            [`.${inputClasses.root}::before`]: { display: 'none' },
            [`.${inputClasses.root}::after`]: { display: 'none' },
          }),
        }),
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      variants: [
        {
          props: {
            variant: 'workshop-submit',
          },
          style: {
            padding: '4px 15px',
            color: colors.white,
            minWidth: '5.1rem',
            backgroundColor: colors.yellowDark,
            letterSpacing: '1px',
            fontSize: '15px',
            '&.Mui-disabled': {
              backgroundColor: colors.backgroundLight,
            },
            '&:hover': {
              backgroundColor: colors.yellowDarkHover,
            },
          },
        },
        {
          props: {
            variant: 'soft-blue',
          },
          style: {
            padding: '4px 15px',
            color: colors.white,
            minWidth: '5.1rem',
            backgroundColor: colors.blueLight,
            letterSpacing: '1px',
            fontSize: '15px',
            '&.Mui-disabled': {
              backgroundColor: colors.backgroundLight,
            },
            '&:hover': {
              backgroundColor: colors.blueDark,
            },
          },
        },
        {
          props: {
            variant: 'contained-secondary',
          },
          style: {
            padding: '4px 15px',
            color: colors.white,
            minWidth: '5.1rem',
            backgroundColor: colors.yellowDark,
            '&.Mui-disabled': {
              backgroundColor: colors.backgroundLight,
            },
            '&:hover': {
              backgroundColor: colors.yellowDarkHover,
            },
          },
        },
        {
          props: {
            variant: 'contained-muted',
          },
          style: {
            padding: '4px 15px',
            color: colors.white,
            minWidth: '5.1rem',
            backgroundColor: colors.background,
            '&.Mui-disabled': {
              backgroundColor: colors.background,
            },
            '&:hover': {
              backgroundColor: colors.backgroundDark,
            },
          },
        },
        {
          props: {
            variant: 'lowercase',
          },
          style: {
            textTransform: 'none',
            letterSpacing: '0',
          },
        },
      ],
      styleOverrides: {
        sizeLarge: {
          fontSize: '1.15rem',
        },
        sizeMedium: {
          fontSize: '0.8725rem',
        },
        sizeSmall: {
          fontSize: '0.8125rem',
        },
        containedSizeSmall: {
          padding: '4px 15px',
        },
        containedSecondary: {
          color: colors.white,
          '&:hover': {
            backgroundColor: colors.yellowDarkHover,
          },
        },
        containedWarning: {
          color: colors.white,
          backgroundColor: colors.redDark,
          '&:hover': {
            backgroundColor: colors.red,
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
        colorSecondary: {
          color: colors.white,
          backgroundColor: colors.yellowDark,
          '&:hover': {
            background: colors.yellowDarkHover,
          },
          '&:disabled': {
            color: colors.white,
            background: colors.yellow,
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          marginRight: '4px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: colors.greyLight,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeLarge: {
          fontSize: '1.8rem',
        },
      },
    },
    MuiPaper: {
      variants: [
        {
          props: {
            variant: 'outlined-dark',
          },
          style: {
            backgroundColor: colors.greyLight,
          },
        },
      ],
    },
    MuiLinearProgress: {
      styleOverrides: {
        colorSecondary: {
          color: colors.yellowDark,
          backgroundColor: colors.white,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: colors.textLight,
          fontSize: '0.7em',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '24px 24px 16px',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          fontSize: '14px',
          fontWeight: '500',
          '&:focus, &:focus-within': {
            outline: 'none',
          },
        },
        columnSeparator: {
          display: 'none',
        },
        columnHeaders: {
          borderColor: colors.greyLight,
        },
        toolbarContainer: {
          '& .MuiInput-underline': {
            boxShadow: `inset 0px 0px 0px 1px ${colors.greyLight}`,
            borderRadius: '5px',
            padding: '0 14px',
            '&:hover': {
              borderColor: colors.yellowDark,
              boxShadow: `inset 0px 0px 0px 1px ${colors.backgroundDark}`,
            },
            '&:focus, &:focus-within': {
              boxShadow: `inset 0px 0px 0px 2px ${colors.yellowDark}`,
            },
            '& svg': {
              marginRight: '8px',
              fill: colors.bgDarkText,
            },
            '& .MuiInputBase-input': {
              padding: '8.5px 0',
            },
          },
        },
        cell: {
          padding: '20px 10px',
          borderColor: colors.greyLight,
          fontSize: '14px',
          lineHeight: '1.5em',
          fontWeight: '300',
          '&:focus, &:focus-within': {
            outline: 'none',
          },
        },
        columnHeaderCheckbox: {
          '& .Mui-checked': {
            color: colors.yellowDark,
          },
        },
        cellCheckbox: {
          '& .Mui-checked': {
            color: colors.yellowDark,
          },
        },
        row: {
          '&.MuiDataGrid-row--lastVisible .MuiDataGrid-cell': {
            borderColor: colors.greyLight,
            borderBottom: `1px solid ${colors.greyLight}`,
          },
        },
        paper: {
          boxShadow: 'none',
        },
        root: {
          border: 0,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        outlinedWarning: {
          borderWidth: '2px',
          borderColor: colors.yellowDark,
          background: colors.yellowLight,
        },
      },
    },
  },
})

export default theme
