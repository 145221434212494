import Image from 'next/image'

export default function Logo({ size = 25 }: { size?: number }) {
  return (
    <Image
      src="/makerconnect-logo.svg"
      alt="makerconnect-logo"
      width={size}
      height={size}
      priority
    />
  )
}
