import { routes } from 'routes'

import { SurveyQuestionAnswerValue, SurveyType, WorkshopLevel, WorkshopType } from 'graphql/types'
import PageContainer from 'components/layouts/PageContainer'
import { styled } from '@mui/material'

export enum WorkshopStatus {
  Future = 'future',
  ProfileQuestionnaireRequired = 'profile_questionnaire_required',
  SurveyRequired = 'survey_required',
  PreWorkRequired = 'pre_work_required',
  PreWorkDone = 'pre_work_done',
  Live = 'live',
  PeerFeedbackRequired = 'peer_feedback_required',
  PeerFeedbackLoading = 'peer_feedback_loading',
  WorkshopFeedbackRequired = 'workshop_feedback_required',
  Resources = 'resources',
  PostSurveyRequired = 'post_survey_required',
  Archive = 'archive',
}

// returned as JSON from BE so we define it
export type WorkshopDataType = {
  id: number
  level: WorkshopLevel
  type: keyof typeof WorkshopType
  is_lw: boolean
  language_code: string
  date: Date
  date_end: Date
  date_survey: Date
  date_expires: Date
  start_time: string | null
  other_participants: {
    id: string
    fullname: string
  }[]
  status: WorkshopStatus
  pre_workshop_survey_id: string
  profile_questionnaire_id: string
  peer_feedback_appear: boolean
  peer_feedback_submitted: boolean
  post_workshop_survey_id: string
  workshop_feedback_submitted: boolean
  days_remaining: string | null
}

export type WorkshopFullDataType = {
  id: number
  name: string
  consultant: string
  date: Date
  date_end: Date
  date_expires: Date
  date_survey: Date
  emails: string
  level: WorkshopLevel
  organization: string
  progress_status: string
  timezone: string
  type: string
}

export enum WorkshopSurveyStatus {
  SelfReported = 'selfReported',
  PostWorkshop = 'postWorkshop',
  PeerFeedbackLoading = 'peerFeedbackLoading',
  PeerFeedback = 'peerFeedback',
}

export enum AdditionalCharacteristicsStatus {
  AreaOfOpportunity = 'areaOfOpportunity',
  StrongestCharacteristics = 'strongestCharacteristics',
}

export enum PeerFeedbackOpenQuestionsType {
  didWell = 'Name one thing this person did well',
  canImprove = 'Name one thing this person needs to improve on',
}

export type PeerFeedbackOpenQuestionAnswers = {
  [key in PeerFeedbackOpenQuestionsType]?: string[]
}

export type PeerFeedbackOpenQuestionResponse = {
  freeText: string
}[]

export const GeneralSurveyAnswerValues = {
  [SurveyQuestionAnswerValue.StronglyDisagree]: 'Strongly Disagree',
  [SurveyQuestionAnswerValue.Disagree]: 'Disagree',
  [SurveyQuestionAnswerValue.Neutral]: 'Neutral',
  [SurveyQuestionAnswerValue.Agree]: 'Agree',
  [SurveyQuestionAnswerValue.StronglyAgree]: 'Strongly Agree',
}

export const Lv3SurveyAnswerValues = {
  [SurveyQuestionAnswerValue.StronglyDisagree]: 'Never',
  [SurveyQuestionAnswerValue.Disagree]: 'Rarely',
  [SurveyQuestionAnswerValue.Neutral]: 'Sometimes',
  [SurveyQuestionAnswerValue.Agree]: 'Most of the time',
  [SurveyQuestionAnswerValue.StronglyAgree]: 'Always',
}

export type SurveyDataType = {
  id: number
  title: string
  type: SurveyType
}

// redirect to Learnworlds
export const handleLearnworldsClick = async (
  setLoading: (state: boolean) => void,
  setError: (state: boolean) => void,
) => {
  try {
    setLoading(true)
    const res = await fetch(routes.api.learnworlds.learnworldsSso, {
      method: 'POST',
    })

    const json = await res.json()
    setLoading(false)

    if (json.errors && json.errors[0] && json.errors[0].message) {
      throw new Error(json.errors[0].message)
    }
    if (json.url) {
      window.open(json.url, '_blank', 'noreferrer')
    } else {
      if (json.errors && json.errors[0] && json.errors[0].message) {
        throw new Error(json.errors[0].message)
      }
      setError(true)
    }
  } catch (error) {
    if (!(error instanceof Error)) {
      throw error
    }
    setError(true)
  }
}

export const WorkshopPageContainer = styled(PageContainer)(({ theme }) => ({
  padding: `${theme.spacing(3)} 0`,
  backgroundColor: theme.palette.bg.dark,
  minHeight: '100%',
}))

export const getSurveyStatus = (wsData: WorkshopDataType) => {
  if (wsData.post_workshop_survey_id) {
    return WorkshopSurveyStatus.PostWorkshop
  }
  if (wsData.peer_feedback_submitted) {
    return wsData.status === WorkshopStatus.PeerFeedbackLoading
      ? WorkshopSurveyStatus.PeerFeedbackLoading
      : WorkshopSurveyStatus.PeerFeedback
  }
  return WorkshopSurveyStatus.SelfReported
}
