import { useRouter } from 'next/router'
import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react'

type PrintContextType = {
  isPrintMode: boolean
  isLandscape: boolean
  enablePrintMode: () => void
  disablePrintMode: () => void
}

const PrintContext = createContext<PrintContextType | undefined>(undefined)

export const usePrintContext = () => {
  const context = useContext(PrintContext)
  if (!context) {
    console.warn('Misuse of usePrintContext. It must be used within a PrintProvider. ')
  }
  return context || { isPrintMode: false, isLandscape: false }
}

export const PrintProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isLandscape, setIsLandscape] = useState(false)
  const [isPrintMode, setIsPrintMode] = useState(false)
  const { query } = useRouter()

  useEffect(() => {
    if (['landscape', 'portrait'].includes(query.printMode as string)) {
      setIsLandscape(query.printMode == 'landscape')
      setIsPrintMode(true)
    }
  }, [query])

  const enablePrintMode = (landscape: boolean = true) => {
    setIsLandscape(landscape)
    setIsPrintMode(true)
  }

  const disablePrintMode = () => {
    setIsPrintMode(false)
    setIsLandscape(false)
  }

  return (
    <PrintContext.Provider value={{ isPrintMode, isLandscape, enablePrintMode, disablePrintMode }}>
      {children}
    </PrintContext.Provider>
  )
}
