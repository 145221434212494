import { useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import { destroyCookie, parseCookies } from 'nookies'
import { useCallback, useEffect } from 'react'

import { cookieDomain } from 'external-routes'
import { userIsSso } from 'roles'
import { routes } from 'routes'

import Preloader from 'components/Preloader'

import { useUser } from './UserProvider'

export function UserStatusProvider({ children }: { children?: React.ReactNode }) {
  const session = useSession()
  const router = useRouter()
  const {
    user,
    isProfileQuestionnaireRequired,
    isPreWorkshopSurveyRequired,
    isPreWorkRequired,
    shouldShowWsProfilePage,
    isPeerToPeerFeedbackRequired,
    isWorkshopFeedbackRequired,
    isPostWorkshopSurveyRequired,
  } = useUser()

  // use cookie to redirect to peer feedback survey on login
  const cookies = parseCookies()

  const shouldRouteRedirect = useCallback(
    (route?: string) => {
      return router.asPath.includes(routes.changePassword) ||
        router.asPath.includes('/print') ||
        router.asPath.includes('%2Fprint') ||
        (route && router.asPath.match(route))
        ? false
        : true
    },
    [router.asPath],
  )

  // check if on profile page
  const isProfilePage = () => {
    return (
      (shouldShowWsProfilePage && !cookies.redirectedToWsProfilePage) ||
      !shouldRouteRedirect(routes.workshop.profileDark)
    )
  }

  // cleanup sso redirect cookie
  useEffect(() => {
    if (cookies.ssoRedirectUrl && !shouldRouteRedirect(cookies.ssoRedirectUrl)) {
      destroyCookie(null, 'ssoRedirectUrl', { path: '/', domain: cookieDomain })
    }
  }, [cookies.ssoRedirectUrl, shouldRouteRedirect])

  // after user is logged in, check what he should do
  if (session.status === 'authenticated') {
    // edge case when user is authenticated but is still on login page (might be because of LaunchDarkly?) or loading data from BE
    if (router.asPath.includes('/login') || router.asPath.includes('/magic-link'))
      return <Preloader fullscreen />
    // don't mess with logout route
    if (router.asPath.includes(routes.logout)) return <>{children}</>
    if (
      // *** Redirect to Change Password ***
      // If the user is not from an SSO company and has not updated their password, redirect to change password page
      user &&
      user.passwordUpdatedAt === null &&
      !userIsSso(user) &&
      shouldRouteRedirect()
    ) {
      router.push(routes.changePassword)
      return <Preloader fullscreen />
    } else if (
      // *** Redirect on SSO login ***
      shouldRouteRedirect(cookies.ssoRedirectUrl) &&
      cookies.ssoRedirectUrl
    ) {
      router.push(String(cookies.ssoRedirectUrl))
      destroyCookie(null, 'ssoRedirectUrl', { path: '/', domain: cookieDomain })
      return <Preloader fullscreen />
    } else if (
      // *** Redirect to Profile Questionnaire ***
      // don't show on change password page
      user &&
      shouldRouteRedirect(routes.workshop.preWorkshop.profileQuestionnaire) &&
      isProfileQuestionnaireRequired
    ) {
      router.push(routes.workshop.preWorkshop.profileQuestionnaire)
      return <Preloader fullscreen />
    } else if (
      // *** Redirect to Pre-Workshop Survey ***
      // if the user should redirect to pre-workshop survey
      user &&
      shouldRouteRedirect(routes.workshop.preWorkshop.preWorkshopSurvey) &&
      isPreWorkshopSurveyRequired
    ) {
      router.push(routes.workshop.preWorkshop.preWorkshopSurvey)
    } else if (
      // *** Redirect to Pre-Work ***
      // if the user should redirect to pre-work
      user &&
      shouldRouteRedirect(routes.workshop.preWorkshop.preWork) &&
      // we redirect to profile after LV3 survey so don't redirect to pre-work if on Profile page
      !isProfilePage() &&
      isPreWorkRequired
    ) {
      router.push(routes.workshop.preWorkshop.preWork)
    } else if (
      // *** Redirect to Peer Feedback Survey ***
      // if the user should redirect to pre-workshop survey
      user &&
      shouldRouteRedirect(routes.workshop.peerFeedbackSurvey) &&
      !cookies.redirectedToPeerFeedbackSurvey &&
      isPeerToPeerFeedbackRequired
    ) {
      router.push(routes.workshop.peerFeedbackSurvey)
      return <Preloader fullscreen />
    } else if (
      // *** Redirect to Workshop Feedback ***
      // if the user should redirect to workshop-feedback
      user &&
      shouldRouteRedirect(routes.workshop.workshopFeedback) &&
      !cookies.redirectedToWorkshopFeedback &&
      isWorkshopFeedbackRequired
    ) {
      router.push(routes.workshop.workshopFeedback)
      return <Preloader fullscreen />
    } else if (
      // *** Redirect to Post-Workshop Survey ***
      // if the user should redirect to workshop-feedback
      user &&
      shouldRouteRedirect(routes.workshop.postWorkshopSurvey) &&
      isPostWorkshopSurveyRequired
    ) {
      router.push(routes.workshop.postWorkshopSurvey)
      return <Preloader fullscreen />
    }
  }
  return <>{children}</>
}
