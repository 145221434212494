import { MenuItem, Select } from '@mui/material'
import { LanguagePair, useWeglot } from 'providers/WeglotProvider'
import { useEffect, useState } from 'react'

export const LanguageSwitcher = () => {
  const instance = useWeglot()
  const { initialized, changeLanguage, selectedLanguage, errorLoading } = instance || {}
  const [weglotLoaded, setWeglotLoaded] = useState(false)

  useEffect(() => {
    if (weglotLoaded) return

    const interval = setInterval(() => {
      if (window.Weglot && initialized) {
        setWeglotLoaded(true)
      }
    }, 200)

    return () => clearInterval(interval)
  }, [weglotLoaded, initialized])

  if (errorLoading || !weglotLoaded) return null // NOTE: on error don't show the switcher

  const availableLanguages =
    window.Weglot?.options?.languages
      ?.map((language: { language_to: string }) => language.language_to)
      ?.concat(window?.Weglot?.options.language_from)
      ?.map((language: string) => ({
        code: language,
        name: window?.Weglot?.getLanguageName(language),
      })) || []

  // NOTE: the .language-switcher class is ignored by weglot in the site's custom config
  return (
    <Select
      value={selectedLanguage}
      onChange={(e) => {
        changeLanguage?.(e.target.value)
      }}
      size="small"
      variant="outlined"
      className="language-switcher"
      sx={{
        display: 'flex',
        flex: 1,
        backgroundColor: 'transparent',
        color: (theme) => `${theme.palette.primary.light}`,
        '& svg': {
          color: (theme) => `${theme.palette.primary.light}`,
        },
      }}>
      {availableLanguages?.map((lang: LanguagePair, index: number) => (
        <MenuItem className="language-switcher" key={index} value={lang.code}>
          {lang.name}
        </MenuItem>
      ))}
    </Select>
  )
}
