import { Typography } from '@mui/material'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { usePrintContext } from 'providers/PrintProvider'
import { useWeglotTranslate } from 'providers/WeglotProvider'
import { Controller, useFormContext } from 'react-hook-form'

export type ControlTextFieldProps = {
  name: string
} & TextFieldProps

export default function ControlTextField({
  name,
  label,
  placeholder,
  ...props
}: ControlTextFieldProps) {
  const { isPrintMode } = usePrintContext()
  const { control } = useFormContext()
  const translatedPlaceholder = useWeglotTranslate(placeholder)

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) =>
        isPrintMode ? (
          <Typography>{value}</Typography>
        ) : (
          <TextField
            fullWidth
            id={name}
            name={name}
            size="small"
            color="secondary"
            variant="outlined"
            label={label}
            value={value}
            error={!!error}
            onChange={onChange}
            sx={{ ...props.sx }}
            helperText={error ? error.message : null}
            {...props}
            placeholder={translatedPlaceholder}
          />
        )
      }
    />
  )
}
