import { ThemeProvider } from '@mui/material/styles'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { ErrorBoundary } from '@sentry/nextjs'
import 'core-js/proposals/global-this'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
import { Session } from 'next-auth'
import { SessionProvider } from 'next-auth/react'
import { AppProps } from 'next/app'
import getConfig from 'next/config'
import { NextPage } from 'next/types'
import { ComponentType, PropsWithChildren, ReactNode } from 'react'
import 'styles/global.css'

import { ToastModeProvider } from 'components/toast/ToastProvider'
import { useFeatureFlag } from 'hooks/useFeatureFlags'
import { AppVersionCheckProvider } from 'providers/AppVersionCheckProvider'
import AuthApolloProvider from 'providers/AuthApolloProvider'
import GtmProvider from 'providers/GtmProvider'
import { LDIdentityProvider } from 'providers/LDIdentityProvider'
import { UserProvider } from 'providers/UserProvider'
import { UserStatusProvider } from 'providers/UserStatusProvider'
import theme from 'theme/theme'
import { PrintProvider } from 'providers/PrintProvider'
import { WeglotProvider } from 'providers/WeglotProvider'
import { ZendeskChatProvider } from 'providers/ZendeskChatProvider'

const {
  publicRuntimeConfig: { googleServiceClientId, LDClientSideID },
} = getConfig()

function MaybeGoogleOAuthProvider({ children }: PropsWithChildren) {
  const googleCalendarIntegrationEnabled =
    useFeatureFlag('googleCalendarIntegration') && googleServiceClientId ? true : false

  if (googleCalendarIntegrationEnabled) {
    return <GoogleOAuthProvider clientId={googleServiceClientId}>{children}</GoogleOAuthProvider>
  }
  return <>{children}</>
}

type Page<P = { session: Session }> = NextPage<P> & {
  getLayout?: (page: ReactNode) => ReactNode
}

type Props = AppProps<{ session: Session }> & {
  Component: Page
}

function App({ Component, pageProps }: Props) {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout || ((page) => page)

  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <SessionProvider
          session={pageProps.session}
          refetchInterval={60 * 30}
          refetchOnWindowFocus={false}>
          <LDIdentityProvider>
            <ToastModeProvider>
              <AuthApolloProvider>
                <MaybeGoogleOAuthProvider>
                  <UserProvider>
                    <GtmProvider>
                      <AppVersionCheckProvider>
                        <WeglotProvider>
                          <PrintProvider>
                            <UserStatusProvider>
                              <ZendeskChatProvider>
                                {getLayout(<Component {...pageProps} />)}
                              </ZendeskChatProvider>
                            </UserStatusProvider>
                          </PrintProvider>
                        </WeglotProvider>
                      </AppVersionCheckProvider>
                    </GtmProvider>
                  </UserProvider>
                </MaybeGoogleOAuthProvider>
              </AuthApolloProvider>
            </ToastModeProvider>
          </LDIdentityProvider>
        </SessionProvider>
      </ThemeProvider>
    </ErrorBoundary>
  )
}

export default withLDProvider({
  clientSideID: LDClientSideID,
})(App as ComponentType)
